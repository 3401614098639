input[type="text"], input[type="email"], input[type="number"], textarea{
    background-color: black;
    color: white;
    text-align:justiy;
    border: 0.3px solid aliceblue !important;
    border-radius: 5px;
}
/* // input[type="text"]:focus,textarea:focus{
//     background-color: white;
//     color: black;
//     text-align:justiy;
//     border: 0.3px solid black !important;
// } */
.text-white
{
    color: white !important;
}
.text-center
{
    text-align: center;
}
.SubmitBtn
{
    /* background-color: rgb(114, 0, 0) !important; */
    background: linear-gradient( 60deg, #9c192f, 20%, #f42835, 40%, #aa0034, 60%, #eb002a, 80%, #b1212b 100% ) !important;
}

.WhenEmailSent
{
    color:white;
}

.text-green
{
    color: green !important;
}

/* remove Number input button */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}